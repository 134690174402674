const DataActivities = [
    'Løpetur',
    'Gåtur',
    'Styrkeøkt',
    'Dans',
    'Yoga',
    'Pilates',
    'Kettlebels',
    'Svømming',
    'Riding',
    'Høypuls',
    'Hagearbeid',
    'Ski',
    'Sykkel',
    'Annet',
    'Husarbeid',
    'Tennis'
];

export default DataActivities;
