export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_INIT = 'REGISTER_USER_INIT';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCCES = 'LOGIN_USER_SUCCCES';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCCES = 'LOGOUT_USER_SUCCCES';
export const LOGOUT_USER_ERROR = 'LOGOUT_USER_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCES = 'DELETE_USER_SUCCES';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const ADD_ACTIVITY_SUCCES = 'ADD_ACTIVITY_SUCCES';
export const ADD_ACTIVITY_ERROR = 'ADD_ACTIVITY_ERROR';

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCES = 'DELETE_ACTIVITY_SUCCES';
export const DELETE_ACTIVITY_ERROR = 'DELETE_ACTIVITY_ERROR';

export const GET_USER_ACTIVITIES = 'GET_USER_ACTIVITIES';
export const GET_USER_ACTIVITIES_SUCCES = 'GET_USER_ACTIVITIES_SUCCES';
export const GET_USER_ACTIVITIES_ERROR = 'GET_USER_ACTIVITIES_ERROR';

export const GET_ALL_USERS_ACTIVITIES = 'GET_ALL_USERS_ACTIVITIES';
export const GET_ALL_USERS_ACTIVITIES_SUCCES = 'GET_ALL_USERS_ACTIVITIES_SUCCES';
export const GET_ALL_USERS_ACTIVITIES_ERROR = 'GET_ALL_USERS_ACTIVITIES_ERROR';
export const UPDATE_USER_ACTIVITIES = 'UPDATE_USER_ACTIVITIES';

export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_ERROR = 'GET_ALL_USERS_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCES = 'UPDATE_USER_SUCCES';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCES = 'CHANGE_PASSWORD_SUCCES';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const GET_USER_DATA = 'GET_USER_DATA';
export const GET_USER_DATA_SUCCES = 'GET_USER_DATA_SUCCES';
export const GET_USER_DATA_ERROR = 'GET_USER_DATA_ERROR';

export const DIALOG_ON = 'DIALOG_ON';
export const DIALOG_OFF = 'DIALOG_OFF';

export const TOAST = 'TOAST';
export const INFO = 'INFO';
export const MESSAGE = 'MESSAGE';

export const POP_UP_MESSAGE = 'POP_UP_MESSAGE';
export const POP_UP_MESSAGE_OFF = 'POP_UP_MESSAGE_OFF';
export const POP_UP_MESSAGE_ON = 'POP_UP_MESSAGE_ON';
